.notfound {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  color: black !important;
  .code-error {
    font-size: 80px;
  }
  a {
    color: black;
    font-weight: 6000;
    text-decoration: underline;
  }
}

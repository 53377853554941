.arrow-change {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  color: white;
  gap: 5px;
  transition: all 0.5s;
  color: inherit;
}

.arrow-active {
  color: #5084e4;
}
.arrow-icon {
  margin-top: 2px;
  transition: all 0.5s;
  transform: rotate(0);
  font-size: 10px;
}
.arrow-show {
  transform: rotate(-180deg);
}

#leftMenu {
  // min-width: 260px;
  width: 25%;
  max-width: 250px;
  margin-right: 40px;
  z-index: 1;
  .menuModule {
    color: #1f3258;
    margin: 0;
    padding: 0;
    font: inherit;
    vertical-align: baseline;
    border: 1px solid #1d3662;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 30px;
    .menuHeader {
      border: none;
      outline: 0;
      cursor: pointer;
      -webkit-box-align: center;
      align-items: center;
      padding: 10px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      letter-spacing: 1px;
      font-weight: 700;
      text-align: left;
      background: linear-gradient(180deg, #3f68bb 0, #1a2e56 100%);
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding-left: 15px;
    }
    .menuSelect {
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      text-decoration: none;
      cursor: pointer;
      background: #1f3258;
      border-bottom: 1px solid #4e6ca8;
      overflow: hidden;
      position: relative;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      padding: 10px;
      width: 100%;
      color: #fff;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 700;
      text-align: left;
      span {
        cursor: pointer;
        color: #fff;
        letter-spacing: 1px;
        text-align: left;
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        z-index: 1;
        font-weight: 400;
      }
    }
  }
}

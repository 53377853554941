.layout-max-help {
  .heading {
    font-size: 0.875rem;
    font-family: Arial, sans-serif;
    font-weight: 400;
    line-height: 1.15;
    color: #c6c6c6;
    box-sizing: border-box;
    border-color: inherit;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
    padding: 0.25rem 0;
    min-height: calc(36px + 0.5rem);
    .text {
      -webkit-text-size-adjust: 100%;
      font-family: Arial, sans-serif;
      line-height: 1.15;
      box-sizing: border-box;
      border-color: inherit;
      margin-right: auto;
      font-size: 1.125rem;
      font-weight: bold;
      color: #1e85ec;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-image: linear-gradient(to right, #1e85ec, rgba(247, 122, 0, 0));
    }
  }
}

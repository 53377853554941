.swiper-pagination-bullet {
  color: #1f3258;
  list-style: none;
  text-align: center;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: inline-block;
  cursor: pointer;
  opacity: 1;
  border-radius: 5px;
  width: 30px;
  height: 5px;
  margin: 0 4px;
  background: rgba(0, 0, 0, 0.3);
  // background: #fab617;
}

.swiper-pagination-bullet-active {
  background: #fab617;
}

.level-banner {
  margin-top: 20px;
}

.layout-default {
  min-height: 100vh;
  height: auto;
}

.background-content {
  background-color: #f5f6fa !important;
  margin-top: 115px;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    margin-top: 65px;
    padding-bottom: 20px;
  }
}

.layout-full {
  width: 100%;
}

.layout-max {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1400px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
}

.hide {
  @media screen and (max-width: 900px) {
    display: none;
  }
}
/*common*/
// .ant-popover-content {
//   .ant-popover-arrow {
//     display: none !important;
//   }
// }

/*header main layout*/

.wallet {
  color: white;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
.wallet-content {
  color: inherit;
  .size-icon {
    font-size: 25px;
  }
}
.lang-item {
  font-size: 13px;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  min-width: 90px;
  cursor: pointer;
  img {
    border-radius: 100%;
    width: 22px;
    height: 22px;
    object-fit: cover;
  }
}
.lang-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px 0;
  width: 125px;
  box-sizing: border-box;
}
.lang {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
.transaction-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  a {
    color: white;
    font-size: 16px;
  }
}

.deposit {
  color: #f77900;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
.transaction {
  color: #fab617;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
.transaction-content {
  color: inherit;
}
.dark-style {
  position: fixed !important;
  .ant-popover-content {
  }
  .ant-popover-inner-content {
    padding: 0px;
  }
  .ant-popover-inner {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
  .ant-popover-arrow-content {
    background: rgba(0, 0, 0, 0.8);
    &::before {
      background: rgba(0, 0, 0, 0.8);
    }
  }
}
.space-top {
  padding: 0;
}

.wallet-info {
  min-width: 300px;
  padding: 15px;
  color: white;
  .total-money {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 600;
    }
  }
  .total-current {
    background-color: rgba(80, 80, 80, 0.5);
    padding: 4px 8px;
    border-radius: 5px;
  }
  .funciton-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 10px 0;
    gap: 10px;
    button {
      width: 50%;
      min-height: 30px;
      background-color: #284985;
      color: white;
      font-weight: 600;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .access-time,
  .transaction-time {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 12px;
    }
  }
}

.info-user {
  color: white;
  .info-user-content {
    display: flex;
    justify-content: center;
    gap: 10px;
    .wraper-img {
      height: 30px;
      width: 36px;
      position: relative;
      @media screen and (max-width: 768px) {
        height: clamp(20px, 3.9062499999vw, 30px);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &::before,
      &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, -50%);
        width: calc(100% + 15px);
        height: calc(100% + 15px);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        animation: ripple 3s infinite;
        // z-index: 2;
        background-image: url('../../../assets//images/animation-vip.png');
      }
      &::before {
        animation-delay: 1s;
      }
      &::after {
        animation-delay: 2s;
      }
    }
  }
}
.logout-icon {
  color: #5084e4;
}
.email-icon {
  font-size: 22px;
  color: white;
  font-weight: 400;
  cursor: pointer;
}

/*Menu style*/
.menu-main-layout {
  background-color: #284985;
  color: white;
  position: fixed;
  left: 0;
  top: 65px;
  z-index: 10;
}

.menu-container {
  height: 50px;
  display: flex;
  align-items: center;

  a:active,
  a:focus,
  a:hover {
    text-decoration: unset;
  }
  a:focus,
  a:hover {
    color: inherit;
  }
  a:active,
  a:hover {
    outline: 0;
  }
  a:active,
  a:focus,
  a:hover {
    text-decoration: unset;
    outline: 0;
  }
  a:hover {
    color: white;
  }
  a {
    color: white;
  }
}
.list-menu,
.list-submenu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-transform: uppercase;
}
.list-menu {
  width: 100%;
  .menu-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: relative;
    color: white;
    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      height: 2px;
      width: 0;
      background-color: #fab617;
      transform: translateX(-50%);
      transition: all 0.2s;
    }
    @media screen and (min-width: 768px) {
      font-size: clamp(10px, 1.302083333333333vw, 14px);
    }
  }
  .menu-item:hover {
    &::before {
      width: 100%;
    }
  }
  .home-icon {
    color: white;
    &:hover {
      color: #fab617;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0;
      width: 0%;
    }
  }
}

.list-game {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0 5px;
  flex-wrap: wrap;
  max-height: calc(100vh - 115px);
  overflow: auto;
}

.modal-submenu {
  padding-top: 12px;
  // margin-top: -14px;
  top: 102px !important;
  width: 100%;
  position: fixed !important;
  .ant-popover-content {
  }
  .ant-popover-inner-content {
    padding: 0px;
    width: 100%;
  }
  .ant-popover-inner {
    background: white;
  }
  .ant-popover-arrow-content {
    background: white;
    &::before {
      background: white;
    }
  }
}

/* footer*/
.footer-main-layout {
  background-color: #141f33;
  .information {
    color: #1f3258;
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    padding: 50px 0 !important;
    width: 100%;
    max-width: initial !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    flex-wrap: wrap;
    gap: 0 6px;
    .item {
      width: calc(25% - 4.5px);
      // margin-right: 10px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      @media screen and (max-width: 1080px) {
        width: calc(50% - 3px);
        margin-bottom: 20px;
      }
      img {
        width: auto;
        height: min-content;
        margin-right: 10px;
      }
      li {
        color: #fbb514;
        font-size: 12px;
        font-family: 'Microsoft YaHei';
        &:nth-child(2) {
          opacity: 0.5;
          color: #fff;
          margin-top: 5px;
          font-size: 12px;
        }
      }
    }
  }
  .payment {
    color: #1f3258;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    max-width: inherit !important;
    margin-top: 20px;
    margin-bottom: 10px;
    .item {
      .title {
        color: #fff;
        font-size: 14px;
        text-transform: none;
        font-weight: 700;
      }
      img {
        width: auto;
        height: 70px;
      }
    }
  }
  .powered {
    color: #fff;
    text-align: center;
    padding: 1px 0 20px 0 !important;
    text-align: center;
    max-width: inherit !important;
    .title {
      margin-bottom: 10px;
      font-weight: 700;
      font-family: 'Microsoft YaHei';
    }
  }
  .platform {
    color: #1f3258;
    display: flex;
    padding-bottom: 20px;
    flex-wrap: wrap;
    align-items: center;
    max-width: inherit !important;
    .item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      button {
        width: auto;
        padding: 0 5px;
        max-width: 100px;
        background: transparent;
        img {
          width: 100%;
        }
      }
    }
  }
}
.webmap-wrapper {
  display: flex;
  justify-content: center;
}
.webmap {
  color: #1f3258;
  vertical-align: baseline;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 24px;
  padding-top: 40px;
  justify-content: flex-start;
  .item {
    margin-right: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    .title {
      color: #fab617;
      line-height: 16px;
      float: left;
      font-weight: 400;
      font-family: 'Microsoft YaHei';
    }
    .sub-item {
      margin-top: 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      button {
        color: #c6c6c6;
        text-align: left;
        background-color: transparent;
      }
      a {
        color: #c6c6c6;
        text-align: left;
      }
    }
    .last-item {
      max-width: 160px;
      color: #c6c6c6;
      text-align: left;
    }
  }
}
.copyright {
  width: 100%;
  max-width: inherit !important;
  background: #202a3d;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  font-size: 12px;
  padding: 10px 0 !important;
  color: #c6c6c6;
  border: 0;
}

/* menu mobile*/
.menu-mobile-layout {
  background-color: white;
}
.walletBox {
  display: flex;
  padding: 2vw 0;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  .side-info {
    width: 35%;
    font-size: 12px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      color: #1e85ec;
    }
    .wrapper-visibility {
      span {
        width: 55px;
      }
    }
  }
  .content {
    display: flex;
    justify-content: flex-end;
    width: 55%;
    font-size: clamp(12px, 2.6041666666vw, 14px);
    min-width: calc(100% - 152px);
    gap: 0 clamp(5px, 1.95312499995vw, 15px);
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #052561;
      font-family: 'Microsoft YaHei';
      span {
        text-align: center;
      }
    }
  }
}

.footerNew {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #141f33;
  padding: 0 10px;
  font-size: clamp(12px, 3.9062499999vw, 30px);
  z-index: 10;
  min-height: clamp(70px, 11.7187499997vw, 90px);
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #64789b;
    text-decoration: none;
    font-size: 3.2vw;
    i,
    .icon {
      font-size: clamp(30px, 4.55729166655vw, 35px);
      line-height: 1;
    }
  }
  .active {
    color: #fab617;
  }
}

.infoCenterMobile {
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-align: center;
  background-color: #141f33;
  padding: 0 3vw 20px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.168627451);
  .title {
    text-align: center;
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background-size: 100%;
    width: 30%;
    padding: 10px 0;
    font-size: clamp(12px, 3.9062499999vw, 30px);
  }
  .list-info {
    text-align: center;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    a {
      text-align: center;
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: hsla(0, 0%, 100%, 0.7);
      img {
        margin-right: 5px;
      }
      p {
        font-size: 3.2vw;
      }
    }
  }
  .AFFILIATEBtn {
    text-align: center;
    margin: 0;
    font-size: clamp(12px, 3.9062499999vw, 30px);
    border: 0;
    font: inherit;
    text-decoration: none;
    width: 100%;
    background: linear-gradient(180deg, #ffd34f 0, #ffc34d);
    padding: 2vw;
    color: #1f2e4d;
    border-radius: 5px;
    display: block;
    box-sizing: border-box;
    margin-top: 30px;
  }
}
.partner {
  background: #141f33;
  background-size: cover;
  padding: 10px 0 20px;
  height: 24vh;

  div {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    width: 100%;
    overflow-x: auto;
    img {
      margin: 2vw 1vw;
      vertical-align: middle;
      height: 40px;
    }
  }
  .bong88 {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    width: 100%;
    overflow-x: auto;
    flex-direction: column;
    align-items: center;
    font-size: 3.2vw;
    color: #fff;
    background: #141f33;
    @media screen and (max-width: 768px) {
      margin-top: 0;
      padding: 0;
      padding-bottom: 100px;
    }
    img {
      margin: 2vw 1vw;
      vertical-align: middle;
      height: 40px;
    }
  }
}
@keyframes ripple {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}
@function ratioMobile($min, $max) {
  @return clamp(#{$min}px, #{(100/768) * $min}vw, #{$max}px);
}

.layout-default {
  .header-main-layout {
    background-color: #1b86a2;
    height: 65px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    @media screen and (max-width: 1400px) {
      padding: 0 0px;
    }
  }

  .header-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left {
      display: flex;
      align-items: center;
      gap: 10px;
      .logo-img {
        height: 52px;
        @media screen and (max-width: 768px) {
          height: clamp(20px, 4.16666666656vw, 32px);
        }
        img {
          max-height: 100%;
          width: auto;
        }
      }
    }
    &__right {
      .function {
        display: flex;
        gap: 5px;
        align-items: center;
      }
      .function {
        display: flex;
        gap: 20px;
      }
      .dowload-app {
        color: #fff92c;
        @media screen and (max-width: 768px) {
          display: none !important;
        }
      }
      .menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        cursor: pointer;
      }
    }
    // @media screen and (max-width: 1024px) {
    //   flex-direction: column;
    //   align-items: flex-start;
    //   &__right {
    //     width: 100%;
    //     .function {
    //       justify-content: space-between;
    //       width: 100%;
    //     }
    //   }
    // }
    @media screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      &__right {
        width: 100%;
        .function {
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }
  .login {
    border: none;
    outline: 0;
    cursor: pointer;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 5px;
    padding: 8px 2px;
    color: #fff;
    min-width: 100px;
    text-transform: uppercase;
    background: #fab617;
  }
}
#goTop {
  position: fixed;
  bottom: calc(clamp(70px, 11.7187499997vw, 90px) + 20px);
  right: 2vw;
  width: clamp(30px, 6.5104166665vw, 50px);
  height: clamp(30px, 6.5104166665vw, 50px);
  background: rgba(250, 182, 23, 0.85);
  border-radius: 100%;
  z-index: 1;
  i {
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    color: #fff;
  }
}

.maintenance {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  .content {
    width: 80%;
    max-width: 500px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    z-index: 2;
    color: #0f2346;
    box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
    border: 1px dashed #0f2346;
    border-radius: 10px;
  }
  &::after {
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background-image: url('../../assets/images/maintenance.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    // max-width: 1200px;
    z-index: 1;
  }
}

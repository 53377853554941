.layout-other {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #141f33;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    font-size: 5vw;
    color: white;
    z-index: 10;
  }
}

.wrapper-info {
  width: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  .heade-info {
    padding: 0 10px 10px;
    border-bottom: 1px solid #ccc;
    margin-left: -10px;
    margin-right: -10px;
    font-weight: 600;
    font-size: 16px;
  }
  .body-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__field-item {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 10px;
      .field-title {
        min-width: 120px;
        text-align: right;
        font-size: 15px;
        font-weight: 600;
      }
      .field-content {
        padding: 10px;
        min-width: 450px;
        background-color: #eeeeee;
        user-select: none;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        .field-title {
          min-width: fit-content;
        }
        .field-content {
          padding: 10px;
          width: 70%;
          min-width: 200px;
          background-color: #eeeeee;
          user-select: none;
        }
      }
    }
  }
}

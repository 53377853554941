.modal-container {
  padding: 0;
  max-width: 760px !important;
  min-width: 700px !important;
  width: 60% !important;
  background-color: rgba(15, 35, 70, 1);
  padding: 20px;
  border-radius: 15px;
  max-height: calc(100% - 160px);
  overflow-y: auto;
  color: #fff;
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  // .ant-modal-body {
  //   padding: 24px 0;
  // }
  .joinloginModule {
    position: relative;
    color: #fff;
    -webkit-box-direction: normal;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    margin: 0 auto;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding-top: 0;
    img {
      width: min-content;
      height: min-content;
      max-width: 50%;
    }
    .joinloginFunction {
      color: #fff;
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      width: 100%;
      margin-left: 40px;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      .title {
        -webkit-box-direction: normal;
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        font-weight: 700;
        margin-bottom: 20px;
        color: #fab617;
      }
      .password {
        margin-bottom: 0;
      }
      .txt-blue2 {
        cursor: pointer;
        color: #1e85ec;
        text-decoration: underline;
        font-size: 14px;
        text-align: left;
        margin-top: 10px;
        background-color: transparent;
      }
      .noAccount {
        font: inherit;
        vertical-align: baseline;
        font-size: 12px;
        margin-top: 15px;
        margin-bottom: 5px;
        color: #fff;
        text-align: left;
      }
      .inputModule {
      }
      .btnGroup {
        color: #fff;
        font: inherit;
        vertical-align: baseline;
        display: flex;
        margin: 10px 0;
        gap: 5px;
        .btn-cancel {
          -webkit-box-direction: normal;
          border: none;
          outline: 0;
          cursor: pointer;
          padding: 7px 10px;
          border-radius: 5px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 129px;
          font-weight: 700;
          background: linear-gradient(180deg, #e6e6e6 0, #c2c2c2 100%);
          color: #000;
        }
        .btn-check {
          -webkit-box-direction: normal;
          border: none;
          outline: 0;
          cursor: pointer;
          padding: 7px 10px;
          border-radius: 5px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 129px;
          margin: 0 5px;
          font-weight: 700;
          background: #284985;
          color: #fff;
          height: auto;
        }
      }
    }
  }
}

.register-container {
  padding: 20px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 0;
  }
  .sub-title {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 0;
  }
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
  .ant-picker {
    width: 100% !important;
  }
  .form-register {
    max-width: 768px;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    .ant-upload-list {
      display: flex;
      justify-content: center;
    }
  }
  .row-form {
    .avatar {
      .ant-form-item-label {
        text-align: center;
      }
    }
    .count-down {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }
    &.ant-row {
      margin: 0 !important;
    }
    .btn-check {
      width: 100%;
      border: none;
      outline: 0;
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 5px;
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: unset;
      min-height: 32px;
      margin: 0 0;
      font-weight: 700;
      background: #284985;
      color: #fff;
      height: auto;
    }
  }
}

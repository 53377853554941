.profile-container {
  .copy-area {
    display: flex;
    gap: 10px;
    align-items: center;
    color: black !important;
    i {
      cursor: pointer;
    }
  }
  .withdraw-label {
    .icon-check {
      color: #008000;
    }
    i {
      color: #dc5353;
      font-size: 25px;
    }
  }
  .wrap-img-list {
    margin-top: 10px !important;
  }
  .img-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .rightModule {
    .infoModule {
      -webkit-box-direction: normal;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      border-radius: 5px;
      background: #e8e8e8;
      margin-bottom: 10px;
      padding: 10px 0;
      position: relative;
      color: #1f3258;
      // min-width: 1100px;
      .inside-title {
        -webkit-box-direction: normal;
        color: #1f3258;
        margin: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        padding: 30px 50px;
        border-bottom: 1px solid #0f2346;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;

        @media screen and (max-width: 768px) {
          padding: 10px;
        }
        span {
          -webkit-box-direction: normal;
          color: #1f3258;
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          font-size: 18px;
          font-weight: 700;
          margin-right: 40px;
          display: block;
          width: fit-content;
          white-space: nowrap;
        }
      }
      .inside-title > div {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      .profileNew {
        -webkit-box-direction: normal;
        color: #1f3258;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        max-width: 850px;
        margin: 0 auto;
        padding: 60px 0 20px 0;
        & > div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-flow: column;
          flex-flow: column;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: flex-start;
        }
        .profileInfo {
          margin: 0 0 0 10px;
          .vipLv {
            margin: 0 0 10px;
            img {
              width: 100px;
              margin-top: 5px;
            }
          }
          & > div {
            background: #fff;
            border-radius: 5px;
            padding: 25px;
          }
          & > div > .title {
            margin-bottom: 10px;
          }
          .vipbg {
            background: 0 0;
            position: relative;
            width: 150px;
            .circle {
              position: absolute;
              width: 120px;
              height: 120px;
              left: calc(50% - 60px);
              top: calc(50% - 60px);
              border-radius: 50%;
              background: url('../../assets/body/vipbg.png') center/contain;
              -webkit-animation: breathing alternate 6s infinite;
              animation: breathing alternate 6s infinite;
              z-index: 0;
            }
            img {
              position: relative;
              z-index: 1;
            }
          }
          .vipLv > div.vipbg {
            // background: url('../../assets//body/vipbg.png') no-repeat center/auto 100%;
            padding: 0 25px;
            margin-top: 0;
          }
          .vipLv > div {
            margin-top: 15px;
            padding: 5px 10px;
            border-radius: 5px;
            color: black;
          }
        }
        .securityRate {
          color: #1f3258;
          -webkit-box-direction: normal;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          margin: 0 0 10px;
          background: #fff;
          border-radius: 5px;
          padding: 25px;
          .rateBar {
            display: flex;
            gap: 15px;
            a {
              display: block;
              width: 30%;
              max-width: 50px;
            }
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .rateBar > div {
            margin: 0 5px 0 0;
          }
          .title.weak ~ .rateBar > div:nth-child(1)::before {
            background: #d83b35;
          }
          .rateBar > div::before {
            content: '';
            display: block;
            width: 75px;
            height: 10px;
            background: #b2b2b2;
            border-radius: 5px;
          }
        }
        .verification {
          .items {
            display: flex;
            & > div {
              background: #e8e8e8;
              width: 50px;
              height: 50px;
              border-radius: 25px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              position: relative;
              margin-right: 10px;
              cursor: pointer;
              img {
                width: 30px;
              }
            }
          }
        }
        .profile {
          background: #fff;
          border-radius: 5px;
          padding: 20px;
          margin: 0 10px;
          width: 100%;
          min-width: 400px;
          position: relative;
          .btn-edit {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 20px;
            border-radius: 10px;
          }
          @media screen and (max-width: 768px) {
            min-width: 100%;
          }
          & > div {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            margin: 0;
            line-height: 35px;

            & .text {
              border-bottom: 1px dashed #ccc;
              flex: auto;
              width: fit-content;
              display: flex;
              align-items: center;
              padding-left: 20px;
              @media screen and (max-width: 400px) {
                width: 100%;
              }
            }
            @media screen and (max-width: 400px) {
              flex-direction: column;
            }
          }
          // & > div:nth-child(odd) {
          //   margin-right: 20px;
          // }
          & > div > div {
            // width: 138px;
            @media screen and (max-width: 768px) {
              // width: auto;
            }
          }
          & > div div:first-child {
            color: #1e85ec;
          }
          & > div > div {
            min-width: 157px;
            img {
              width: 100%;
              max-width: 100px;
              height: 100px;
              object-fit: scale-down;
            }
            @media screen and (max-width: 768px) {
              // min-width: unset;
            }
          }
          & > div > div > button {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: #fab617;
            padding: 5px;
            border-radius: 3px;
            margin: 5px 0;
            color: white;
            height: 26px;
          }
        }
        @media screen and (max-width: 1100px) {
          display: flex;
          flex-direction: column;
          padding: 10px 5px;
          .profileInfo {
            margin: 0;
          }
          .vipLv {
            max-width: 100%;
          }
          .profile {
            margin: 0;
          }
        }
      }
      .profile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-width: 750px;
        margin: 60px auto 60px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        > div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 calc(50% - 20px);
          flex: 0 0 calc(50% - 20px);
          line-height: 35px;
        }
      }

      .vipLv {
        color: #1f3258;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        display: flex;
        max-width: 700px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
        padding: 25px;
        margin: 0 0 10px;
      }
      .title {
        background: 0 0;
        font-size: 14px;
        height: auto;
        font-weight: 700;
        color: #1f3258;
      }
    }
  }
}

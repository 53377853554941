.info-container {
  .title-main {
    padding: 2px 5px;
    font-size: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: uppercase;
    color: #141f33;
    font-weight: 700;
  }
  .indexInfo {
    padding: 25px 0;
    display: flex;
    position: relative;
  }
  .infoCenter {
    margin-right: 25px;
    width: 40%;
    max-width: 355px;
    @media screen and (max-width: 1400px) {
      max-width: unset;
    }
    .infoGroup {
      margin-top: 10px;
      a {
        display: flex;
        align-items: center;
        background: #e8eaed;
        border-radius: 5px;
        overflow: hidden;
        color: #1f2e4d;
        font-size: 16px;
        margin-bottom: clamp(0px, 1.4285714284vw, 20px);
        text-decoration: none !important;
        transition: all 0.5s;
        &:hover {
          background-color: #fff;
        }
      }
      i {
        cursor: pointer;
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: clamp(0px, 10.714285713vw, 150px);
        height: clamp(0px, 5.21428571366vw, 73px);
        font-size: 35px;
        color: #fff;
        background: url('../../../assets/body/infobg.png') no-repeat top right/cover;
        padding-right: 25px;
        box-sizing: border-box;
      }
    }
  }
  .news {
    width: calc(100% - 355px);
    position: relative;
    @media screen and (max-width: 1400px) {
      width: 60%;
    }
    .btnall {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      font-size: 12px;
      border-radius: 20px;
      background: -webkit-gradient(linear, left top, left bottom, from(#4263a6), to(#334d80));
      background: linear-gradient(to bottom, #4263a6 0, #334d80 100%);
      text-transform: uppercase;
      cursor: pointer;
      padding: 7px 15px;
      a {
        color: white;
      }
    }
    .articleModule {
      display: flex;
      margin-top: 10px;
      align-items: normal;
      justify-content: space-between;
      width: 100%;
      gap: 10px;
      a {
        flex: 1;
        // margin-right: 30px;
        width: 33.33333333%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 320px;
        overflow: hidden;
        img {
          max-height: 226px;
          height: clamp(0px, 13.5714285698vw, 190px);
          border-radius: 5px 5px 0 0;
          position: relative;
          object-fit: cover;
          z-index: 0;
        }
        .title {
          background: #e8eaed;
          color: #1f2e4d;
          padding: 10px 15px;
          min-height: 38px;
          border-radius: 0 0 5px 5px;
          height: 70px;
          box-sizing: border-box;
          position: relative;
          z-index: 1;
          font-weight: 600;
          height: clamp(0px, 4.9999999994vw, 70px);

          p {
            opacity: 0.5;
            font-size: 14px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

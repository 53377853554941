.modal-container {
  padding: 0;
  max-width: 760px !important;
  min-width: 700px !important;
  width: 60% !important;
  background-color: rgba(15, 35, 70, 1);
  border-radius: 15px;
  max-height: calc(100% - 160px);
  overflow-y: auto;
  color: #fff;
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  // .ant-modal-body {
  //   padding: 24px 0;
  // }
  .pop {
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    background: rgba(15, 35, 70, 0.95);
    border-radius: 15px;
    max-height: calc(100% - 160px);
    overflow-y: auto;
    color: #fff;
  }
  .pop > div.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .pop > div {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 600px;
    max-width: 700px;
    position: relative;
    .ant-col {
      width: 100%;
    }
    .title {
      -webkit-box-direction: normal;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      font-weight: 700;
      margin: 10px 0 20px;
      color: #fff;
      margin-bottom: 20px;
    }
    .title + div {
      text-align: center;
    }
    .txt {
      color: #fff;
      -webkit-box-direction: normal;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      width: calc(100% - 10px);
      padding: 0 5px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .txt > div:first-child {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-flex: 1;
      -ms-flex: auto;
      flex: auto;
      margin-right: 10px;
    }
    .txt-blue2 {
      color: #1e85ec;
    }
    .btnGroup {
      color: #fff;
      -webkit-box-direction: normal;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      display: flex;
      margin-bottom: 10px;
      .btn-cancel {
        -webkit-box-direction: normal;
        border: none;
        outline: 0;
        cursor: pointer;
        padding: 7px 10px;
        border-radius: 5px;
        -webkit-box-flex: 1;
        flex: 1;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        min-width: 129px;
        margin: 0 5px;
        font-weight: 700;
        background: linear-gradient(180deg, #e6e6e6 0, #c2c2c2 100%);
        color: #000;
      }
      .btn-check {
        -webkit-box-direction: normal;
        border: none;
        outline: 0;
        cursor: pointer;
        padding: 7px 10px;
        border-radius: 5px;
        -webkit-box-flex: 1;
        flex: 1;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        min-width: 129px;
        margin: 0 5px;
        font-weight: 700;
        background: #284985;
        color: #fff;
        height: auto;
      }
    }
  }
}
.modale-basic {
  .disable {
    pointer-events: none;
    opacity: 0.7;
  }
}

.background-event {
  background: white;
  margin: 20px auto;
  border-radius: 15px;
  @media screen and (max-width: 1400px) {
    padding: 0;
    margin: 0;
    border-radius: 0;
  }
  .title {
    color: #1f3258;
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    padding: 30px 50px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-bottom: 1px solid #0f2346;

    span {
      font-size: 18px;
      font-weight: 700;
      margin-left: 15px;
      display: block;
    }
    i {
      font-size: clamp(20px, 3.571428571vw, 50px);
    }
  }
  .tab-event {
    .ant-tabs-nav {
      // display: none !important;
    }
    padding: 20px;
    .ant-tabs-ink-bar {
      display: none !important;
    }
    .ant-tabs-nav-wrap {
      // overflow: unset;
    }
    .ant-tabs-nav-operations {
      display: none !important;
    }

    .ant-tabs-nav-list {
      width: 100%;

      @media screen and (max-width: 768px) {
        // width: auto;
        min-width: 768px;
        .ant-tabs-tab {
          // width: auto;

          min-width: fit-content;
          white-space: nowrap;
        }
      }
    }
    .ant-tabs-tab {
      margin-left: 0;
      width: calc(16.6666666667%);
      font-size: clamp(10px, 0.99999999988vw, 14px);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #284985;

      background-color: #c6c6c6;
      button {
        background-color: transparent;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 600;
        }
      }
    }
    .ant-tabs-tab-active {
      background: #284985;
      .ant-tabs-tab-btn {
        color: #fff;
      }
    }
  }
}

.proListNew {
  color: #1f3258;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  // padding: 0 20px
  flex-flow: wrap;

  .item {
    margin-bottom: 20px;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    width: 100%;
    padding: 15px;
    background: #fff;
    border-radius: 5px;
    color: #1e85ec;
    position: relative;
    display: flex;
    gap: 0 clamp(0px, 2.8571428568vw, 40px);
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
    .new {
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      position: absolute;
      top: 15px;
      left: 15px;
      background: 0 0;
      border-radius: 0;
      border-style: solid;
      border-width: 70px 90px 0 0;
      border-color: #fab617 transparent transparent transparent;
      width: 0;
      height: 0;
      padding: 0;
      color: #fff;
      display: none;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      font-weight: 700;
      transform: rotate(0);
    }
    .promoImg {
      color: #1e85ec;
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      width: clamp(0px, 32.142857139vw, 450px);
      height: 170px;
      overflow: hidden;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      @media screen and (max-width: 900px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .promoInfo {
      color: #1e85ec;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-flow: column;
      width: 570px;
      // max-width: 550px;
      @media screen and (max-width: 1400px) {
        width: clamp(0px, 40.7142857094vw, 570px);
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        margin-top: 10px;
      }
      .txt-darkBlue {
        -webkit-box-direction: normal;
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        color: #284985;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
      }
      .txt-gray {
        -webkit-box-direction: normal;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        color: #666;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        white-space: unset;
        font-weight: 400;
        margin: 0 0 10px;
      }
      .btnGroup {
        color: #1e85ec;
        -webkit-box-direction: normal;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        display: flex;
        gap: 10px;
        @media screen and (max-width: 900px) {
          font-size: 12px;
        }
        .yellow {
          -webkit-box-direction: normal;
          border: none;
          outline: 0;
          cursor: pointer;
          padding: 7px 10px;
          border-radius: 5px;
          -webkit-box-flex: 1;
          flex: 1;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          font-weight: 700;
          background: #fab617;
          color: #fff;
          font-family: inherit;
          white-space: nowrap;
          max-width: 150px;
          width: calc(50% - 5px);
        }
        .btn-check {
          -webkit-box-direction: normal;
          border: none;
          outline: 0;
          cursor: pointer;
          padding: 7px 10px;
          border-radius: 5px;
          -webkit-box-flex: 1;
          flex: 1;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          font-weight: 700;
          background: #284985;
          color: #fff;
          font-family: inherit;
          white-space: nowrap;
          width: calc(50% - 5px);
          max-width: 150px;
        }
        @media screen and (max-width: 900px) {
          .yellow,
          .btn-check {
            width: calc(50% - 5px);
            max-width: unset;
            height: 40px;
          }
        }
      }
    }
    .remain {
      color: #1e85ec;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      width: clamp(0px, 16.4285714266vw, 230px);
      padding: 10px 0;
      text-align: center;
      font-weight: 700;
      @media screen and (max-width: 900px) {
        display: none;
      }
      .date {
        text-align: center;
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        color: #fa9f17;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
      }
    }
  }
}

.pagination-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.promotionsInfo {
  .yellow {
    -webkit-box-direction: normal;
    border: none;
    outline: 0;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 5px;
    -webkit-box-flex: 1;
    flex: 1;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 700;
    background: #fab617;
    color: #fff;
    font-family: inherit;
    white-space: nowrap;
    max-width: 150px;
    width: calc(50% - 5px);
  }
}

.news {
  .rightModule {
    padding: 25px;
    margin-top: 20px;
    .pagination-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .inside-title {
      padding: 30px 50px;
      border-bottom: 1px solid #0f2346;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      span {
        font-size: 18px;
        font-weight: 700;
        margin-right: 40px;
        display: block;
      }
    }
    .newsList {
      color: #1f3258;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      display: flex;
      padding: 20px 50px;
      flex-wrap: wrap;
      line-height: 20px;
      justify-content: flex-start;
      gap: 30px 15px;
      width: 100%;
      @media screen and (max-width: 768px) {
        padding: 10px;
      }
      & > div {
        width: calc(33.3333333333% - 10px);
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 3px 15px -3px rgba(0, 0, 0, 0.1);
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
        .txt-darkBlue {
          -webkit-box-direction: normal;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          color: #284985;
          font-weight: 700;
          font-size: 12px;
          margin: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
        .txt-gray {
          -webkit-box-direction: normal;
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          color: #666;
          font-size: 12px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
        .btnGroup {
          color: #1e85ec;
          -webkit-box-direction: normal;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          margin: 10px 0 0;
          .blue {
            -webkit-box-direction: normal;
            border: 0;
            font: inherit;
            vertical-align: baseline;
            text-decoration: none;
            cursor: pointer;
            padding: 7px 10px;
            border-radius: 5px;
            -webkit-box-flex: 1;
            flex: 1;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 129px;
            margin: 0 5px;
            font-weight: 700;
            background: #284985;
            color: #fff;
          }
        }
      }
    }
  }
}

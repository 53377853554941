.hot-news-container {
  padding-top: 25px;
  padding-bottom: 25px;
  a h2.title {
    padding: 2px 5px;
    font-size: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: uppercase;
    color: #141f33;
    line-height: 1.35;
  }

  .topGame {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    button {
      width: 24%;
      height: clamp(145px, 17.4999999979vw, 245px);
      position: relative;
      border-radius: 5px;
      background: transparent;
      overflow: hidden;
      z-index: 1;
      padding: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.5s;
      }
      .item {
        width: 100%;
        height: 100%;
      }
      .title {
        cursor: pointer;
        margin: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        font-weight: 700;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 12% 100%);
        padding: 10px 0 10px clamp(16px, 2.28571428544vw, 32px);
        font-size: clamp(10px, 0.99999999988vw, 14px);
        line-height: 1;
        width: 60%;
        text-align: center;
        background: linear-gradient(to bottom, #ffd34f 0, #ffc34d 100%);
        position: absolute;
        text-transform: uppercase;
        top: -1px;
        right: -1px;
        color: #141f33;
      }
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
  .bottomGame {
    color: #1f3258;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
    button {
      background: 0 0;
      border: none;
      padding: 0;
      outline: 0;
      cursor: pointer;
      color: #fff;
      display: flex;
      width: calc(20% - 8px);
      height: clamp(145px, 17.857142855vw, 250px);
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 0.5s;
      }
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
      .item {
        width: 100%;
        height: 100%;
      }
      .title {
        cursor: pointer;
        color: #fff;
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        font-weight: 700;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(to bottom, #4263a6 0, #334d80 100%);
        width: 100%;
        bottom: 0;
        left: 0;
        height: clamp(0px, 3.571428571vw, 50px);
        line-height: 3.5;
        border-radius: 0 0 3px 3px;
        text-transform: uppercase;
        font-size: clamp(10px, 0.99999999988vw, 14px);
        line-height: 1;
      }
    }
  }
}

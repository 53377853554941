.table-mobile {
    .table-mobile-item {
        cursor: pointer;
        border-bottom: 1px solid #efefef;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .item-image {
        background-color: #efefef;
        object-fit: contain;
        max-width: 50px;
        max-height: 60px;
    }

    .flex-column {
        gap: 5px;
        display: flex;
        flex-direction: column;
    }
}
.member-containber {
}
.deposit-member {
  .ant-upload-picture-card-wrapper {
    .ant-tooltip-inner {
      display: none !important;
    }
  }
  .rightModule {
    color: #1f3258;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    margin: unset;
    display: flex;
    flex-direction: column;
    .infoModule {
      -webkit-box-direction: normal;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      border-radius: 5px;
      background: #e8e8e8;
      margin-bottom: 10px;
      padding: 10px 0;
      position: relative;
      color: #1f3258;
      // min-width: 1100px;
      .icon-icon32px_deposit {
        position: relative;
      }
      .inside-title {
        padding: 30px 50px;
        border-bottom: 1px solid #0f2346;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        @media screen and (max-width: 768px) {
          padding: 10px 10px;
        }
        span {
          -webkit-box-direction: normal;
          color: #1f3258;
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          font-size: 18px;
          font-weight: 700;
          margin-right: 40px;
          width: fit-content;
          white-space: nowrap;
          @media screen and (max-width: 768px) {
            margin-right: 10px;
          }
          display: block;
        }
        div {
          -webkit-box-direction: normal;
          color: #1f3258;
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          max-width: 400px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        a {
          -webkit-box-direction: normal;
          margin: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          text-decoration: none;
          cursor: pointer;
          position: absolute;
          right: 50px;
          color: #fff;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          background: linear-gradient(180deg, #3f68bb 0, #1a2e56 100%);
          padding: 10px;
          border-radius: 5px;
          font-size: 20px;
          span {
            margin: 0;
            font-size: 14px;
            color: #fff;
          }
        }
      }
      .subModuleListColumn {
        padding: 20px;
        width: 100%;
        @media screen and (max-width: 768px) {
          padding: 10px;
        }
        .subModuleTitle {
          color: #1f3258;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          position: relative;
          margin: 10px 0;
        }
        .subModule {
          -webkit-box-direction: normal;
          margin: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          background: #1f3258;
          padding: 20px;
          border-radius: 15px;
          margin-bottom: 20px;
          flex-wrap: wrap;
          align-items: center;
          color: #fff;
          position: relative;
          .img-QR {
            max-width: 340px;
          }
          .ant-form-item {
            width: 100%;
            margin: 0;
          }
        }
        .subModule > div {
          display: flex;
          flex-wrap: wrap;
          gap: 5px 6px;
        }
        .subModule > div > button {
          background: transparent;
          border: none;
          padding: 0;
          outline: 0;
          cursor: pointer;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          flex-direction: column;
          -webkit-box-align: center;
          align-items: center;
          color: #fff;
          width: 172px;
          position: relative;
          @media screen and (max-width: 768px) {
            width: calc((100% / 3) - 4px);
            min-width: 150px;
            & > div {
              width: 100%;
            }
          }
          .icon {
            margin-bottom: 10px;
            border-radius: 15px;
            width: 100px;
            padding: 10px;
            background: #fff;
            position: relative;
            -webkit-transition: 0.7s;
            transition: 0.7s;
            padding: 13px 5px;
            @media screen and (max-width: 768px) {
              max-width: 100px;
              width: 100%;
            }
          }
          .main-icon {
            font-size: 48px;
            color: #1e85ec;
          }
          .bank {
            cursor: pointer;
            -webkit-box-direction: normal;
            padding: 0;
            border: 0;
            font: inherit;
            vertical-align: baseline;
            width: 100%;
            color: #fff;
            margin: 0 0 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            div {
              cursor: pointer;
              -webkit-box-direction: normal;
              color: #fff;
              margin: 0;
              padding: 0;
              border: 0;
              font: inherit;
              vertical-align: baseline;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:last-child {
                color: #fab617;
                margin: 10px 0 0;
                width: 100%;
              }
            }
          }
        }
        .subModule > div > button > div:first-child {
          // box-shadow: 0 0 0 6px #1e85ec inset;
          margin-bottom: 10px;
          border-radius: 15px;
          padding: 10px;
          background: #fff;
          position: relative;
          -webkit-transition: 0.7s;
          transition: 0.7s;
          img {
            width: 100%;
          }
        }
        .subModule.select-bank > div > button > div:first-child {
          // box-shadow: 0 0 0 6px #1e85ec inset;

          height: 50px;
          width: 100px;
          img {
            width: 100%;
            max-height: 100%;
          }
        }
        .subModule > div > button.active > div:first-child,
        .subModule > div > button:hover > div:first-child {
          box-shadow: 0 0 0 6px #1e85ec inset;
        }
        .ant-upload-list-item {
          background-color: white !important;
        }

        .note {
          font-size: 16px;
          color: white;
        }
        .bankQR {
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: nowrap;
          @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
          }
          .bankAccount {
            width: 80%;
            flex-direction: column;
            font-size: 16px;
            .smallList {
              color: #fff;
              -webkit-box-direction: normal;
              margin: 0;
              padding: 0;
              border: 0;
              font: inherit;
              vertical-align: baseline;
              margin-top: 10px;
              ul {
                display: flex;
                li {
                  min-width: 130px;
                  width: auto;
                  line-height: 30px;
                  letter-spacing: 1px;
                  font-size: clamp(12px, 1.95312499995vw, 15px);
                  white-space: nowrap;
                  &:first-child {
                    min-width: 130px;
                    width: auto;
                  }
                  &:nth-child(odd) {
                    color: #1e85ec;
                  }
                }
              }
            }
          }
          .qrCode {
            -webkit-box-direction: normal;
            color: #fff;
            margin: 0;
            border: 0;
            font: inherit;
            vertical-align: baseline;
            display: flex;
            flex-wrap: wrap;
            width: calc(20% - 20px);
            height: 126px;
            padding: 5px;
            overflow: hidden;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            img {
              -webkit-box-direction: normal;
              color: #fff;
              margin: 0;
              padding: 0;
              border: 0;
              font: inherit;
              vertical-align: baseline;
              width: auto;
              height: 100%;
            }
          }
        }
      }
      .btnGroup {
        -webkit-box-direction: normal;
        color: #1f3258;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        display: flex;
        margin: 10px auto;
        width: max-content;
        button {
          -webkit-box-direction: normal;
          border: none;
          outline: 0;
          padding: 7px 10px;
          border-radius: 5px;
          -webkit-box-flex: 1;
          flex: 1;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          min-width: 129px;
          margin: 0 5px;
          font-weight: 700;
          background: #666;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}

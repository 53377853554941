.ant-modal-wrap {
  @media screen and (max-width: 768px) {
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }
}
.modal-container-event {
  padding: 0;
  max-width: 1200px !important;
  min-width: 700px !important;
  width: 75% !important;
  background-color: white;
  border-radius: 15px;
  max-height: calc(100% - 160px);
  overflow-y: auto;
  color: #fff;
  table {
    td,
    th {
      border: 0.5px solid #f0f0f0;
    }
  }
  @media screen and (max-width: 900px) {
    width: 100% !important;
    min-width: inherit !important;
    // height: 100vh;
    margin: 0 !important;
    max-width: inherit !important;
    border-radius: 0;
  }
  .ant-modal-header {
    background-color: transparent;
    border: none;
    @media screen and (max-width: 900px) {
      background-color: #284985;
    }
  }
  .ant-modal-close {
    right: 0px;
    @media screen and (max-width: 900px) {
    }
  }
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-body {
    margin: 20px 0;
    overflow: auto;
    padding: 0;
    @media screen and (max-width: 900px) {
      padding: 0 24px;
    }
  }
  .promotionsInfo {
    color: #1f3258;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    position: relative;
    height: calc(100vh - 180px);
    padding: 5px 40px;
    table {
      width: 100%;
    }
    @media screen and (max-width: 900px) {
      padding: 0;
      height: calc(100vh - 100px);
    }

    img {
      font: inherit;
      vertical-align: baseline;
      width: 100%;
      border-radius: 15px;
      margin-top: 15px;
      height: 200px;
      // object-fit: scale-down;
      // dispatch(getEventDetail({ id }));
      @media screen and (max-width: 900px) {
        display: none;
      }
    }
    .proData {
      font: inherit;
      vertical-align: baseline;
      margin-top: 0;
      border-radius: 0 0 5px 5px;
      padding: 20px 0 0;
      @media screen and (max-width: 900px) {
        padding: 0;
      }
      .proDataTitle {
        color: #1f3258;
        font: inherit;
        vertical-align: baseline;
        display: flex;
        align-items: center;
        @media screen and (max-width: 900px) {
          display: flex;
          flex-direction: column;
          flex-direction: column-reverse;
        }
        .txt-blue2 {
          font: inherit;
          vertical-align: baseline;
          color: #1e85ec;
          flex: 3;
          font-size: 20px;
          font-weight: 700;
          text-transform: uppercase;
          .txt-blue4 {
            font: inherit;
            vertical-align: baseline;
            color: #5084e4;
            font-weight: 400;
            font-size: 16px;
          }
          @media screen and (max-width: 900px) {
            font-size: clamp(25px, 3.99999999996vw, 36px);
            width: 100%;
            color: #fab617;
            .txt-blue4 {
              color: white;
            }
          }
        }
        .btnGroup {
          color: #1f3258;
          font: inherit;
          vertical-align: baseline;
          display: flex;
          margin: 30px 0;
          -webkit-box-flex: 1;
          flex: 1;
          -webkit-box-pack: end;
          justify-content: flex-end;
          @media screen and (max-width: 900px) {
            width: 100%;
          }
          .yellow {
            cursor: pointer;
            border-radius: 5px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 129px;
            font-weight: 700;
            background: #fab617;
            color: #fff;
            margin: 0 0 0 5px;
            padding: 6px;
            max-width: 130px;
            @media screen and (max-width: 900px) {
              width: 100%;
              max-width: inherit;
              height: 40px;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .rolloverNew > div {
    display: flex;
    background-color: white;
    font-size: 11pt;
    text-align: center;
  }
  .rolloverNew > div > div {
    flex: 1;
    border: 1px solid #ccc;
  }
  .rolloverNew > div:first-child {
    background-color: #f5ac23;
    color: white;
    font-weight: 700;
  }
}

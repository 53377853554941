@font-face {
  font-family: myFirstFont;
  src: url('./assets/font/chinese.msyh.ttf');
}

@font-face {
  font-family: 'Microsoft YaHei';
  src: url('./assets/font/chinese.msyh.ttf');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4d5259;
  width: 100% !important;
  overflow-y: unset !important;
}

#root {
  min-height: 100vh;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.red-color {
  color: red;
}
.btn-confirm {
  background-color: #1a2e56 !important;
  color: white !important;
}
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
.download-excel-button {
  border: none;
  background: #10ad4c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  padding: 6px 18px 6px 18px;
  border-radius: 3px;
  cursor: pointer;
}
.download-excel-button img {
  width: 25px;
  margin-left: 5px;
}
.excel-export-container {
  margin: 25px 0px;
}
.excel-table {
  width: 100%;
}
.excel-table-wrapper {
  margin: 15px 0px;
  max-height: 200px;
  overflow-y: scroll;
}
.excel-table tr:first-of-type {
  display: none;
}
.excel-table tr {
  background-color: #c6e6f5;
}
.excel-table tr:nth-of-type(2) {
  background-color: #3d5662 !important;
  color: #fff;
}
.excel-table tr:nth-of-type(even) {
  background-color: #e6fbff;
}
.excel-table td {
  padding: 5px 10px;
}
.file-upload {
  display: grid;
  grid-gap: 10px;
  position: relative;
  z-index: 1;
  line-height: 1.333em;
}
.file-upload label {
  display: flex;
  align-items: center;
  transition: 0.4s ease;
  font-family: arial, sans-serif;
}
.file-upload input {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 40px;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  font-family: arial, sans-serif;
  font-size: 1rem;
  font-weight: regular;
  box-sizing: border-box;
}
.file-upload input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
  margin-left: 10px;
  padding: 0;
  height: 40px;
  width: 0;
}
.file-upload button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  line-height: 0;
  user-select: none;
  color: white;
  background-color: #10ad4c;
  border-radius: 0 3px 3px 0;
  font-size: 1rem;
  font-weight: 800;
  box-sizing: border-box;
  border: 0px;
}
.excel-import-container {
  padding: 25px;
  box-shadow: 0 0 20px rgba(66, 50, 98, 0.35);
  border: 1px solid #eaeaea;
  border-radius: 3px;
  background: white;
}

.ant-row {
  width: 100% !important;
}

.ant-modal-wrap {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  bottom: auto;
  right: auto;
}

.ant-modal {
  top: auto;
}

.ant-modal-title {
  text-transform: uppercase;
  color: white;
}
.ant-modal-header {
  background-color: #001529;
}
.ant-modal-close-icon {
  color: black;
  font-weight: bold;
}
.conent-page table td,
.conent-page table th {
  border: 0.5px solid #f0f0f0;
}

.mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }
}
/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
  cursor: pointer;
} */

#leftMenuMobile {
  position: fixed;
  top: 65px;
  left: 0;
  display: flex;
  gap: 20px;
  background-color: white;
  width: 100%;
  height: 50px;
  padding: 0 10px;
  z-index: 99;
  .arrow-change {
    width: fit-content;
    font-size: clamp(14px, 2.6041666666vw, 20px);
  }
}
.menu-member-mobile {
  top: 110px !important;
  position: absolute;
}

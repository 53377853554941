.withdraw {
  .rightModule {
    color: #1f3258;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    margin: unset;
    display: flex;
    flex-direction: column;
    .infoModule {
      -webkit-box-direction: normal;
      // min-width: 1100px;
      width: 100%;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      border-radius: 5px;
      background: #e8e8e8;
      margin-bottom: 10px;
      padding: 10px 0;
      position: relative;
      color: #1f3258;
      // min-width: 1100px;
      .inside-title {
        padding: 30px 50px;
        border-bottom: 1px solid #0f2346;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        @media screen and (max-width: 768px) {
          padding: 10px 10px;
        }
        span {
          -webkit-box-direction: normal;
          color: #1f3258;
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          font-size: 18px;
          font-weight: 700;
          margin-right: 40px;
          display: block;
          width: fit-content;
          white-space: nowrap;
        }
        div {
          -webkit-box-direction: normal;
          color: #1f3258;
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          max-width: 400px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
        a {
          -webkit-box-direction: normal;
          margin: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          text-decoration: none;
          cursor: pointer;
          position: absolute;
          right: 50px;
          color: #fff;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          background: linear-gradient(180deg, #3f68bb 0, #1a2e56 100%);
          padding: 10px;
          border-radius: 5px;
          font-size: 20px;
          span {
            margin: 0;
            font-size: 14px;
            color: #fff;
          }
        }
      }
      .infoModuleLeft {
        -webkit-box-direction: normal;
        color: white;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        float: left;
        width: 100%;
        @media screen and (max-width: 768px) {
          padding: 10px !important;
        }
        .walletWrapper {
          width: calc(100% - 40px);
          margin: 20px auto;
          padding: 10px 0;
          border-radius: 15px;
          background: #1f3258;
          font-size: 13px;
          @media screen and (max-width: 768px) {
            width: 100%;
            margin: 0;
            padding: 10px !important;
          }
          .walletModule {
            -webkit-box-direction: normal;
            border: 0;
            font: inherit;
            vertical-align: baseline;
            display: flex;
            -webkit-box-align: start;
            align-items: flex-start;
            width: calc(100% - 40px);
            margin: 0;
            -webkit-box-pack: justify;
            // flex-direction: column;
            justify-content: flex-start;
            gap: 10px;
            padding: 0 20px;
            color: #fff;
            flex-wrap: wrap;
            @media screen and (max-width: 768px) {
              margin: 0;
              padding: 10px 0;
              width: 100%;
              flex-direction: column;
            }
            .bank-item {
              width: 30%;
              min-width: 200px;
              display: flex;
              flex-direction: column;
              font-size: 16px;
              background-color: white;
              color: #0f2346;
              padding: 10px;
              border-radius: 10px;
              font-weight: 700;
              position: relative;
              transition: all 0.5s;
              box-sizing: border-box;
              border: 5px solid transparent;
              cursor: pointer;
              @media screen and (max-width: 768px) {
                width: 100%;
                max-width: 200px;
              }
              span {
                text-align: left;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              img {
                max-width: 100%;
                height: 50px;
                object-fit: contain;
              }
            }
            .bank-item.active {
              border: 5px solid #008000;
            }
            .walletBalanceModule {
              -webkit-box-direction: normal;
              border: 0;
              font: inherit;
              vertical-align: baseline;
              background: #1e283e;
              border-radius: 5px;
              padding: 10px;
              margin: 10px 0 25px;
              -webkit-box-flex: 1;
              flex: 1;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              justify-content: space-between;
              max-width: calc(50% - 25px);
              position: relative;
              color: #fff;
              min-height: 38px;

              span.icon-balance {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                color: #fab617;
                i {
                  -webkit-box-flex: 0;
                  -ms-flex: 0 0 auto;
                  flex: 0 0 auto;
                  color: #1e85ec;
                  margin-right: 15px;
                  font-size: 24px;
                }
              }
            }
            .addBank {
              -webkit-box-direction: normal;
              color: #fff;
              margin: 0;
              border: 0;
              font: inherit;
              vertical-align: baseline;
              width: 100%;
              padding: 10px 0;
              display: flex;
              flex-wrap: wrap;
              .txt-yellow {
                color: #fab617;
              }
            }
            .addBank .addBankName.noBank {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              justify-content: flex-start;
              cursor: pointer;
              min-height: 75px;
              .modale-basic {
                width: 100%;
                display: flex;
                align-items: center;
                .open-btn {
                  width: 100%;
                  min-height: 75px;
                  display: flex;
                  align-items: center;
                }
              }
            }
            .addBank .addBankName {
              width: calc(33.3333333333% - 50px);
              background: #1e283e;
              border-radius: 5px;
              padding: 20px;
              margin-bottom: 15px;
              margin-right: 10px;
              position: relative;
              box-sizing: initial;
            }
            .addBank .addBankName .icon-plus,
            .addBank .addBankName .icon-warning {
              font-size: 40px;
              display: flex;
              flex: 0 0 auto;
              color: #1e85ec;
              margin-right: 15px;
            }
          }
          .subModuleList {
            padding: 20px;
            @media screen and (max-width: 768px) {
              padding: 0;
            }
            .ant-form-item-label {
              label {
                color: white !important;
              }
            }
            .btnGroup {
              -webkit-box-direction: normal;
              color: #fff;
              padding: 0;
              border: 0;
              font: inherit;
              vertical-align: baseline;
              display: flex;
              margin: 10px auto;
              display: flex;
              justify-content: flex-start;
              .btn-check {
                width: 100%;
                -webkit-box-direction: normal;
                border: none;
                outline: 0;
                cursor: pointer;
                padding: 7px 10px;
                border-radius: 5px;
                -webkit-box-flex: 1;
                flex: 1;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                font-weight: 700;
                background: #284985;
                color: #fff;
              }
            }
          }
        }
        & > .walletWrapper {
          padding: 10px 20px;
        }
      }
    }
    .ant-col-12 {
      min-width: 350px !important;
      @media screen and (max-width: 768px) {
        min-width: 100% !important;
      }
    }
  }
}

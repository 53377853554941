.btn-nav {
  color: white;
  font-size: clamp(25px, 5.85937499985vw, 45px);
  display: none;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.nav-help-wrapper {
  top: 65px;
  .ant-drawer-content {
    background: #1d3662;
  }
  .ant-drawer-content-wrapper {
    width: 100%;
    max-width: 320px;
  }
  .menu-body-container {
    padding: 0;
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: none;
    }
    .ant-menu-item-only-child {
      padding: 0 !important;
      .ant-menu-title-content {
        padding: 0 48px;
      }
    }
    .ant-menu-sub {
      .ant-menu-item-only-child {
        // padding: 0 24px !important;
      }
    }
    .ant-menu-root > li {
      // padding: 0 24px;
    }
    .ant-menu-submenu-title {
      padding: 0 !important;
      margin: 0;
    }
    .ant-menu-title-content {
      padding: 0 24px;
    }
    .ant-menu-root > .ant-menu-item-only-child .ant-menu-title-content {
      padding: 0 24px !important;
    }

    padding-bottom: 20px;
    .ant-menu-submenu-arrow {
      color: white !important;
    }
    .ant-menu-submenu-active,
    .ant-menu-item-active {
      background-color: #1e85ec;
      color: white !important;
    }
    .ant-menu-light .ant-menu-submenu-title:hover {
      color: white;
    }
  }
}
.container-drawr {
  display: none;
}

#Marquee {
  margin-top: 20px;
}
.marquee-box {
  color: #1f3258;
  // padding: 0 10px 0 55px;
  font: inherit;
  background: linear-gradient(to bottom, #fff 0, #e6eaf2 100%);
  border: 1px solid #4264a6;
  overflow: hidden;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  height: 35px;
  border-radius: 35px;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .img-sound {
    padding: 0 15px;
    z-index: 2;
    background: linear-gradient(to bottom, #fff 0, #e6eaf2 100%);
    img {
      color: #1f3258;
      width: 35px;
      height: auto;
      z-index: 1;
    }
  }
}
.marquee-run {
  color: #1f3258;
  display: flex;
  flex-direction: row;
  width: 100%;

  // animation: runMarquee 30s infinite linear;
  button {
    background: transparent;
    white-space: nowrap;
  }
}

@keyframes runMarquee {
  0% {
    transform: translateX(calc(100% - 10px));
  }
  100% {
    transform: translateX(-100%);
  }
}

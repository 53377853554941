.register-container {
  padding: 20px;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
  .ant-picker {
    width: 100% !important;
  }
  .form-register {
    max-width: 768px;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    .ant-upload-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .row-form {
    .avatar {
      .ant-form-item-label {
        text-align: center;
      }
    }
    &.ant-row {
      margin: 0 !important;
    }
    .btn-check {
      width: 100%;
      border: none;
      outline: 0;
      cursor: pointer;
      padding: 7px 10px;
      border-radius: 5px;
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 129px;
      margin: 0 5px;
      font-weight: 700;
      background: #284985;
      color: #fff;
      height: auto;
    }
  }
}

.info-bank {
  .rightModule {
    .infoModule {
      -webkit-box-direction: normal;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      border-radius: 5px;
      background: #e8e8e8;
      margin-bottom: 10px;
      padding: 10px 0;
      position: relative;
      color: #1f3258;
      // min-width: 1100px;
      .inside-title {
        -webkit-box-direction: normal;
        color: #1f3258;
        margin: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        padding: 30px 50px;
        border-bottom: 1px solid #0f2346;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        width: 100%;
        @media screen and (max-width: 768px) {
          padding: 10px 10px;
        }
        span {
          font-size: 18px;
          font-weight: 700;
          margin-right: 40px;
          display: block;
          width: fit-content;
          white-space: nowrap;
        }
        div {
          max-width: 400px;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }
      .accountName {
        padding: 20px 22px 10px;
        color: #1e85ec;
        span {
          color: #4e6ca8;
          margin: 0 10px;
        }
      }
      .walletModule {
        -webkit-box-direction: normal;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        display: flex;
        -webkit-box-align: start;
        align-items: flex-start;
        width: calc(100% - 40px);
        margin: 0;
        -webkit-box-pack: justify;
        justify-content: flex-start;
        padding: 0 20px;
        color: #fff;
        flex-wrap: wrap;
        @media screen and (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }
        .addBank {
          -webkit-box-direction: normal;
          color: #fff;
          margin: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          width: 100%;
          padding: 10px 0;
          display: flex;
          flex-wrap: wrap;
          .addBankName.noBank {
            -webkit-box-direction: normal;
            color: #fff;
            margin: 0;
            border: 0;
            font: inherit;
            vertical-align: baseline;
            width: 100%;
            background: #1e283e;
            border-radius: 5px;
            padding: 20px;
            margin-bottom: 15px;
            margin-right: 10px;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;
            min-height: 75px;
            box-sizing: initial;
            .icon {
              // color: #1e283e;
              font-size: 40px;
            }
            .txt-yellow {
              color: #fab617;
            }
          }
          .remainder {
            color: #919191;
            text-align: left;
            width: 100%;
          }
        }
        .haveBank {
          width: fit-content;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          position: relative;
          .addBankName {
            background: #1e283e;
            border-radius: 5px;
            padding: 20px;
            margin-bottom: 15px;
            margin-right: 10px;
            position: relative;
            box-sizing: initial;
          }
          .close {
            position: absolute;
            cursor: pointer;
            top: 7px;
            right: 7px;
          }
        }
        .haveBank > div.addBankName {
          .addBankInfo {
            -webkit-box-direction: normal;
            color: #fff;
            margin: 0;
            padding: 0;
            border: 0;
            font: inherit;
            vertical-align: baseline;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center;
            flex-direction: column;
            .bankName {
              -webkit-box-direction: normal;
              color: #fff;
              border: 0;
              font: inherit;
              vertical-align: baseline;
              padding: 5px 30px;
              height: 40px;
              max-width: 200px;
              border-radius: 30px;
              background: #fff;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              width: fit-content;
              position: relative;
              margin: 0 0 5px 0;
              img {
                max-width: 100%;
                height: auto;
              }
            }
            .addBankInfo {
              -webkit-box-direction: normal;
              color: #fff;
              margin: 0;
              padding: 0;
              border: 0;
              font: inherit;
              vertical-align: baseline;
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              -webkit-box-align: end;
              align-items: flex-end;
              .txt-blue2 {
                color: #1e85ec;
              }
            }
          }
        }
      }
    }
  }
}

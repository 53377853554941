.modale-view {
}
.modal-container-view {
  display: flex;
  justify-content: center;
  img {
    margin: 0 auto;
    max-width: 250px;
    height: auto;
  }
  .ant-modal-wrap {
    width: auto !important;
  }
  .ant-modal-close {
    .ant-modal-close-icon {
      color: black;
    }
  }
}

.game-item {
  width: 200px;
  height: 203px;
  background: url('../../../assets/menu/dropbg_b2.png') no-repeat center/auto 100%;
  position: relative;
  .item-bg {
    cursor: pointer;
    color: #fff;
    font: inherit;
    vertical-align: baseline;
    width: fit-content;
    max-width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  .item-img {
    font: inherit;
    vertical-align: baseline;
    width: fit-content;
    max-width: 150px;
    width: 80%;
    position: absolute;
    bottom: 0;
    left: calc(50% + 25px);
    transform: translateX(-50%);
    z-index: 0;
    transition: all 0.5s;
  }
  .item-icon {
    cursor: pointer;
    font: inherit;
    vertical-align: baseline;
    max-width: 150px;
    font-size: 10px;
    position: absolute;
    color: #fff;
    width: 100px;
    left: 62px;
    top: 5px;
  }
  span {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    min-height: 100%;
    color: #1f3258;
    transition: all 0.5s;
    background: url('../../../assets/menu/droptitle_b.png') no-repeat center/auto 100%;
    z-index: 4;
  }
  &:hover {
    .item-img {
      bottom: 10px;
    }
    span {
      background: url('../../../assets/menu/droptitle_a.png') no-repeat center/auto 100%;
      color: white;
    }
  }
}

.quickLink {
  position: fixed;
  right: -10px;
  top: 15%;
  z-index: 4;
  width: 215px;
  overflow: hidden;
  display: flex;
  transform: translateX(78%);
  transition: 0.7s;
  padding-right: 10px;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.8;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.quickLink > div:nth-child(1) {
  background: #fbb514;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px 10px;
  -webkit-box-flex: 0.5;
  -ms-flex: 0.5;
  flex: 0.5;
  height: 200px;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
}
.quickLink > div:nth-child(2) {
  background: #fff;
  -webkit-box-flex: 3.5;
  -ms-flex: 3.5;
  flex: 3.5;
  padding: 5px 10px;
  z-index: 3;
}
.quickLink > div:nth-child(2) ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
.quickLink > div:nth-child(2) ul li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 25px;
  color: #666;
  margin-bottom: 15px;
}
.quickLink > div:nth-child(2) ul li a {
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.quickLink > div:nth-child(2) ul li a img {
  width: 25px;
  display: block;
  margin-right: 2px;
}
.quickLink > div:nth-child(2) ul li > img {
  max-width: 123px;
  display: block;
  margin-left: 20px;
}
.quickLink.active,
.quickLink:hover {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: 0.7s;
  transition: 0.7s;
  opacity: 1;
}
.btn-contact {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  position: relative;
  padding: 0 20px !important;
  div {
    position: absolute;
    bottom: 50px;
    transform: rotate(270deg);

    i {
      margin-right: 30px;
    }
  }
}
.eventLink {
  position: fixed;
  left: 10px;
  bottom: 0;
  z-index: 4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  @media screen and (max-width: 768px) {
    left: calc(100% - 30vw);
  }
}
.eventLink.vgaming {
  bottom: 200px;
  @media screen and (max-width: 768px) {
    bottom: 70vw;
  }
}
.eventLink.vgaming .icon-close {
  top: 10px;
  background: #5c5c5c;
  color: #cecece;
}
.eventLink .icon-close {
  color: #fbd68d;
  position: absolute;
  left: 10px;
  top: -20px;
  font-size: clamp(20px, 3.9062499999vw, 30px);
  font-weight: 700;
  z-index: 4;
  border-radius: 100%;
  background: rgba(144, 0, 0, 0.8);
  cursor: pointer;
  @media screen and (max-width: 768px) {
    left: 22vw;
  }
}
.eventLink img {
  width: 190px;
  animation: routate linear infinite alternate 0.8s;
  @media screen and (max-width: 768px) {
    width: 28vw;
  }
}

.eventLink.wheel a img.spin {
  z-index: 2;
}

.eventLink.wheel {
  bottom: 190px;
  @media screen and (max-width: 768px) {
    bottom: 65vw;
  }
}
.eventLink.wheel img {
  width: 170px;
  position: absolute;
  top: 0;
  left: 0;
  @media screen and (max-width: 768px) {
    width: 28vw;
  }
}
.eventLink.wheel a img.inner {
  -webkit-animation: wheel 1.5s infinite;
  animation: wheel 1.5s infinite;
  z-index: 1;
  margin-top: 5px;
  margin-left: -2px;
}

@keyframes wheel {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes routate {
  0% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }

  50% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

  100% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

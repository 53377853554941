.btn-nav {
  color: white;
  font-size: clamp(25px, 5.85937499985vw, 45px);
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}
.nav-wrapper {
  .ant-drawer-content {
    background: #f2f4f7;
  }
}
.top-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .logo {
    max-width: clamp(200px, 39.062499999vw, 300px);
    img {
      width: 100%;
    }
  }
  .close-nav {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 30px;
  }
  .username {
    font-size: clamp(12px, 3.9062499999vw, 30px);
    margin-top: clamp(0px, 3.9062499999vw, 30px);
  }
  .balance {
    display: flex;
    gap: 10px;
    margin-top: clamp(0px, 3.9062499999vw, 30px);
    font-size: clamp(12px, 3.9062499999vw, 30px);
    .wrapper-visibility {
      span {
        font-size: clamp(12px, 3.9062499999vw, 30px);
        width: auto;
      }
    }
  }
}
.container-drawr {
  display: none;
}
.nav-wrapper {
  .menu-body-container {
    padding: 0 24px;
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: none;
    }
    .ant-menu-item-only-child {
      padding: 0 !important;
    }
    .ant-menu-submenu-inline {
      border-bottom: 1px solid #ccc;
    }
    .ant-menu-sub {
      .ant-menu-item-only-child {
        padding: 0 24px !important;
      }
    }
    .ant-menu-root > li {
      border-bottom: 1px solid #ccc;
    }
    .ant-menu-submenu-title {
      padding: 0 !important;
    }
    padding-bottom: 20px;
  }
  .thBar {
    margin-top: 10px;
    white-space: nowrap;
    color: #052561;
    visibility: visible;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    z-index: 2;
    background: #152849;
    padding: 3vw 24px;
    display: flex;
    background-color: #e3e7ee;
    bottom: auto;
    margin-left: -24px;
    margin-right: -24px;
    a {
      flex: 1;
      color: #1f2e4d;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-weight: 400;
      font-size: clamp(12px, 3.25520833325vw, 25px);
      i {
        font-size: clamp(12px, 6.5104166665vw, 50px);
      }
      &:nth-child(2) {
        border-right: 1px solid #64789b;
        border-left: 1px solid #64789b;
      }
    }
  }
}

.contact {
  // padding: 0 24px;
  .title {
    font-size: clamp(14px, 3.9062499999vw, 30px);
    color: rgba(0, 0, 0, 0.85);
  }
  .list-social {
    display: flex;
    a {
      display: block;
      img {
        max-width: clamp(80px, 13.020833333vw, 100px);
      }
    }
  }
}
.lang-mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: clamp(14px, 3.9062499999vw, 30px);
  color: rgba(0, 0, 0, 0.85);
  .item-lang {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      object-fit: cover;
    }
  }
  .active {
    background-color: #fbb514;
    padding: 5px 10px;
    border-radius: 50px;
  }
}

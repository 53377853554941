.invite {
  .infoModule {
    -webkit-box-direction: normal;
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    border-radius: 5px;
    background: #e8e8e8;
    margin-bottom: 10px;
    padding: 10px 0;
    position: relative;
    color: #1f3258;
    // min-width: 1100px;
    .inside-title {
      -webkit-box-direction: normal;
      color: #1f3258;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      padding: 30px 50px;
      border-bottom: 1px solid #0f2346;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      width: 100%;

      @media screen and (max-width: 768px) {
        padding: 10px;
      }
      span {
        font-size: 18px;
        font-weight: 700;
        margin-right: 40px;
        display: block;
        width: fit-content;
        white-space: nowrap;
      }
    }
    .icon-friend + .title {
      width: 100%;
      margin: 15px 0 0;
      padding: 0 70px 0 80px;
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .infoModule .title {
      background: 0 0;
      font-size: 14px;
      height: auto;
      color: #1f3258;
    }

    .title {
      font-weight: 700;
    }
    .achievement {
      color: #fff;
      width: 100%;
      margin: 15px auto 0 auto;
      padding: 0 70px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      @media screen and (max-width: 1100px) {
        padding: 0 20px;
      }
    }

    .achievement_item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      padding: 20px 30px;
      border-radius: 5px;
      background: #1f3258;
      @media screen and (max-width: 768px) {
        padding: 5px;
        font-size: 12px;
        span {
          font-size: 12px;
        }
      }
    }
    .achievement_item:nth-child(1) {
      margin-right: 15px;
    }
    .achievement_item span {
      font-size: 20px;
      font-weight: 700;
      margin-top: 10px;
      color: #fab617;
    }
    .txt-lightgray {
      font-weight: 400;
      width: calc(100% - 150px);
      margin: 15px 0 0;
      padding: 0 70px 0 80px;
      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 20px;
        margin: 0;
      }
    }
    .achievement + .txt-lightgray {
      color: #505050;
    }
    .refbanner {
      display: block;
      width: 100%;
      margin: 2vw auto;
      padding: 3vw 70px;
      background: rgba(31, 50, 88, 0.2);
      border-radius: 5px;
      @media screen and (max-width: 1100px) {
        padding: 3vw 20px;
      }
    }
    .referralModule {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin: 15px 0;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 70px;
      @media screen and (max-width: 1100px) {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
      }
    }
    .referralModule > div {
      margin-right: 10px;
      width: calc(35% - 10px);
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }
    .referralModule > div .title {
      margin-bottom: 20px;
      padding-left: 10px;
    }
    .referralModule > div .refBox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      background: #1f3258;
      border-radius: 5px;
      padding: 20px;
      color: #fff;
    }
    .referralModule > div .refBox > div {
      width: 100%;
      border-radius: 0;
      padding: 10px;
    }
    .referralModule > div .refBox > div div {
      line-height: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-bottom: 1px solid #fff;
    }
    .referralModule > div .refBox > div span {
      color: #fab617;
    }
    .referralModule > div .refBox > div a.more,
    .referralModule > div .refBox > div button.more {
      font-size: 16px;
      display: block;
      margin: 0 auto;
      text-align: center;
      font-size: 12px;
      border: 1px solid #fab617;
      padding: 5px;
      margin-top: 10px;
      text-transform: uppercase;
      text-decoration: none;
      width: 100%;
      background: transparent;
    }
    .referralModule > div.refLink {
      width: 100%;
      @media screen and (max-width: 1100px) {
        width: 100%;
      }
    }
    .referralModule > div:last-child {
      margin-right: 0;
    }
    .referralModule > div .title {
      margin-bottom: 20px;
      padding-left: 10px;
    }
    .referralModule > div.refLink .refBox {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    .referralModule > div .refBox.mt-2 {
      margin-top: 40px;
    }
    .referralModule > div .refBox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      background: #1f3258;
      border-radius: 5px;
      padding: 20px;
      color: #fff;
    }
    .referralModule > div.refLink .refBox > div {
      width: calc(100% - 20px);
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .referralModule > div.refLink .refBox > div > a {
      width: calc(70% - 5px);
      float: left;
      line-height: 34px;

      color: white;
      &:nth-child(even) {
        width: calc(30% - 5px);
      }
    }
  }
}

.other-page {
  font: inherit;
  background: #f3f3f3;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: column;
  padding: 26vw 4vw 40vw;
  font-size: clamp(20px, 3.9062499999vw, 30px);
  // padding-top: 65px;
  .webmap {
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    flex-wrap: wrap;
    line-height: 10vw;
    padding: 6vw 4vw 0;
    background: rgba(29, 54, 98, 0.1);
    div {
      width: 100%;
      margin-bottom: 5vw;
      span {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        font-weight: 700;
        color: #1e85ec;
      }
      a {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        text-decoration: none;
        display: flex;
        color: #052561;
      }
    }
    .list-partner {
      margin: 0;
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      width: 100%;
      margin-bottom: 5vw;
      .partner-bar {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        width: 100%;
        margin-bottom: 5vw;
        img {
          padding: 0;
          border: 0;
          font: inherit;
          margin: 2vw 1vw;
          vertical-align: middle;
          height: 40px;
        }
        a {
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          text-decoration: none;
          color: #052561;
          display: inline-block;
          img {
            color: #052561;
            padding: 0;
            border: 0;
            font: inherit;
            margin: 2vw 1vw;
            vertical-align: middle;
            height: 40px;
          }
        }
      }
    }
  }
}

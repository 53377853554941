.edit-profile {
  padding: 0 10px;
  width: 100%;
  position: relative;
  .ant-upload-picture-card-wrapper {
    .ant-tooltip-inner {
      display: none !important;
    }
  }
  .btn-back {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    font-size: 20px;
    z-index: 2;
  }
  @media screen and (max-width: 768px) {
    padding: 10px;

    .btn-back {
      top: 20px;
      right: 20px;
    }
  }
  .ant-picker {
    width: 100% !important;
  }
  .form-register {
    max-width: 768px;
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    background-color: white;
    .ant-upload-list {
      display: flex;
      justify-content: center;
    }
  }
  .row-form {
    .avatar {
      .ant-form-item-label {
        text-align: center;
      }
    }
    &.ant-row {
      margin: 0 !important;
    }
    .btn-check {
      width: 100%;
      border: none;
      outline: 0;
      cursor: pointer;
      padding: 7px 10px;
      border-radius: 5px;
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 129px;
      margin: 0 5px;
      font-weight: 700;
      background: #284985;
      color: #fff;
      height: auto;
    }
  }
}

.tab-menu {
  .tab-item {
    white-space: nowrap;
    border-radius: 5px;
    margin: 0 2px;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    // font-size: 2.6vw;
    flex: 0 0 60px;
    width: 100%;
    height: clamp(60px, 13.020833333vw, 100px);
    background: linear-gradient(180deg, #334d80 0, #4263a6);
    box-shadow: inset 0 1px 2px #1a2843;
    color: white;
    p {
      font-size: clamp(0px, 2.6041666666vw, 20px);
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-nav-list {
    gap: 5px;
  }
  .ant-tabs-tab-active {
    .tab-item {
      background: linear-gradient(180deg, #ffd34f 0, #ffc34d);
      color: #1f2e4d;
      box-shadow: inset 0 -2px 1px #b88c36;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .list-news {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px;
    gap: 15px 5px;
    .game {
      padding: 0;
      border: 0;
      vertical-align: baseline;
      display: inline-block;
      width: calc(33.33% - 5px);
      line-height: 0;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        border-radius: 5px 5px 0px 0px;
        object-fit: cover;
        height: 110px;
      }
      .title {
        margin: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        font-weight: 700;
        padding: 14px 10px;
        box-sizing: border-box;
        text-align: center;
        background: linear-gradient(180deg, #4d6ba7 0, #1b3159);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        border-radius: 0 0 5px 5px;
        font-size: 16px;
      }
    }
  }
}

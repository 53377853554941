.change-pass {
  .infoModule {
    border-radius: 5px;
    background: #e8e8e8;
    margin-bottom: 10px;
    padding: 10px 0;
    position: relative;
    color: #1f3258;
    // min-width: 900px;
    .inside-title {
      padding: 30px 50px;
      border-bottom: 1px solid #0f2346;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      @media screen and (max-width: 768px) {
        padding: 10px;
      }
    }
    .inside-title span {
      font-size: 18px;
      font-weight: 700;
      margin-right: 40px;
      display: block;
      width: fit-content;
      white-space: nowrap;
    }
    .inside-title > div {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .changePassWord {
      max-width: 855px;
      margin: 60px auto 60px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      @media screen and (max-width: 768px) {
        margin: 20px auto 20px;
      }
      form {
        .btn-check {
          width: 100%;
          background: #284985;
          color: #fff;
        }
      }
    }
  }
  .form-changepass {
    width: 100%;
    padding: 10px;
  }
  .ant-col-24 {
    min-width: 350px !important;
    @media screen and (max-width: 768px) {
      min-width: 100% !important;
    }
  }
}

.menu-mobile {
  .ant-menu-title-content {
    text-transform: uppercase;
  }
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      // border-bottom: 1px solid #ccc;
      font-size: clamp(14px, 3.9062499999vw, 30px);
    }
    .ant-menu-submenu-arrow {
      right: 12px;
    }
  }
  .ant-menu-sub {
    .ant-menu-title-content {
      font-size: clamp(14px, 3.9062499999vw, 30px);
    }
  }
  .menu-main-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    text-transform: uppercase;
  }
  .ant-menu-item {
    padding-right: 34px;
  }
  .title-single {
    font-size: clamp(14px, 3.9062499999vw, 30px);
    // border-bottom: 1.5px solid #ccc !important;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #f2f4f7;
  }

  background: #f2f4f7 !important;
}

.layout-pc {
  width: 100%;
  .vipInfo {
    color: #1f3258;
    vertical-align: baseline;
    display: flex;
    flex-wrap: wrap;
    background: rgba(31, 50, 88, 0.1);
    border-radius: 15px;
    padding: 20px 40px;
    margin: 60px auto;
    .vipTitle {
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      font-size: 18px;
      margin: 20px 0;
      font-weight: 700;
      color: #284985;
      width: 100%;
    }
  }

  .medal {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    border-collapse: collapse;
    border-spacing: 0;
    color: #1f3258;
    margin-bottom: 20px;
    tr {
      td {
        border-collapse: collapse;
        border-spacing: 0;
        color: #1f3258;
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        width: 6%;
        text-align: center;
        img {
          border-collapse: collapse;
          border-spacing: 0;
          color: #1f3258;
          text-align: center;
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          width: 100%;
          transform: scale(0.8);
        }
        &:first-child {
          border-collapse: collapse;
          border-spacing: 0;
          color: #1f3258;
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          width: 34%;
          text-align: left;
          vertical-align: bottom;
        }
      }
    }
  }

  .eventList {
    color: #1f3258;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    font-weight: 400;
    width: 100%;

    .icon-nova88 {
      font-weight: 700;
      font-size: 16px;
    }
    tr td {
      border-radius: 0;
      border: 0;
      width: 6%;
      line-height: 30px;
      text-align: center;
      padding: 1px 0;
      background: rgba(255, 255, 255, 0.2);
      text-transform: uppercase;
      white-space: pre-line;
      word-break: break-all;
      font-size: clamp(8px, 0.99999999988vw, 14px);
    }
    tr.title {
      background: #4e6ca8;
      color: #fff;
    }
    tr:first-child td {
      color: #fff;
    }
    tr td:first-child {
      text-align: left;
      width: 34%;
      padding-left: 10px;
    }
    tr:not(.title):not(.lastTd) td:first-child {
      border-right: 1px solid #b2b2b2;
      background: rgba(232, 232, 232, 0.5);
    }
    tr:not(.title):not(.lastTd):hover td {
      background: #4e6ca8;
      color: #fff;
    }
  }

  .event-link {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
  }
}

.layout-sp {
  width: 100%;
  .swiper-pagination-bullet {
    margin-top: 10px;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #666;
    opacity: 1;
    // background: #fab617;
  }

  .swiper-pagination-bullet-active {
    background: red;
  }
  .swiper-pagination {
    bottom: 0 !important;
  }

  .level-banner {
    margin-top: 0px;
    position: fixed;
    top: 65px;
  }
  .medal {
    font: inherit;
    vertical-align: baseline;

    color: #fff;
    width: 100%;
    font-size: 10px;
    display: flex;
    word-break: break-word;
    padding: 0 10px;

    div {
      color: #fff;
      margin: 0;
      font: inherit;
      vertical-align: baseline;
      display: flex;
      word-break: break-word;
      flex: 1 1;
      border: 1px solid #001942;
      background: #134dae;
      text-align: center;
      padding: 1vw;
      flex-direction: column;
      align-items: center;
      border-left: 0;
      img {
        color: #fff;
        word-break: break-word;
        text-align: center;
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        width: 100%;
      }
    }
    div:first-child {
      border-left: 0;
    }
  }
  .vipInfo {
    border: 0;
    font: inherit;
    vertical-align: baseline;
    color: #052561;
    margin-top: calc(clamp(0px, 45.5729166655vw, 350px) + 65px);
    padding-top: 20px;
    .vipTitle {
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      font-weight: 700;
      color: #fab617;
      margin: 2vw 0;
    }
  }
  .vipPage {
    .text {
      color: #052561;
      margin: 1vw 2vw;
    }
    .eventList {
      vertical-align: baseline;
      border-spacing: 0;
      display: flex;
      border-collapse: collapse;
      tbody {
        width: 100%;
      }
      .title {
        color: #fff;
        font-weight: 700;
      }
      tr {
        display: flex;
        background: #212121;
      }
      tr.title td {
        background: #1e85ec !important;
        text-transform: uppercase;
      }

      tr td {
        background: #7c7d7e;
        padding: 1vw 0;
        font-weight: 400;
        font-size: 3vw;
        height: auto;
        border-radius: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-flex: 1 1;
        flex: 1 1;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        border: 1px solid #f0eff5;
        border-left: 0;
        border-bottom: 0;
        word-break: break-word;
        color: #fff;
        text-align: center;
      }
      tr td:first-child {
        border-left: 1px solid #f0eff5;
      }
      tr td img {
        width: 10vw;
      }
      tr:first-child td {
        justify-content: flex-start;
      }
      tr:not(.title) td[colspan='12'] {
        background: #000;
        text-transform: uppercase;
      }
    }
    .vipBanner {
      vertical-align: baseline;
      width: 100%;
      height: 49.5vw;
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin-top: 3vw;
      img {
        max-width: 100%;
      }
    }
  }
}

.member-page {
  font: inherit;
  background: #f3f3f3;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: #f2f4f7;
  padding: 26vw 4vw 40vw;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  .avatar {
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    width: min-content;
    height: 100%;
    margin: 5vw auto;
    position: relative;
    border-radius: 100%;
    color: #fff;
    .circle {
      color: #fff;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      padding: 1vw;
      text-align: center;
      position: absolute;
      width: 30vw;
      height: 30vw;
      left: calc(50% - 16vw);
      top: calc(50% - 21vw);
      border-radius: 50%;
      background: url('../../assets/body/vipbg.png') 50% / contain;
      animation: breathing 2s infinite alternate;
      z-index: 0;
    }
    img {
      color: #fff;
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      width: 26vw;
      padding: 5vw;
      position: relative;
      z-index: 1;
    }
    div {
      margin-top: 10px;
      color: #fff;
      font: inherit;
      vertical-align: baseline;
      background: #1e85ec;
      padding: 1vw;
      border-radius: 5px;
      text-align: center;
    }
  }
  .account {
    border: 0;
    font: inherit;
    vertical-align: baseline;
    background: #1f3258;
    border-radius: 5px;
    margin: 2vw 0;
    padding: 4vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 3.5vw;
    color: #052561;
    .info {
      padding: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      color: #64789b;
      flex: 4 1;
      margin: 0 0 0 2vw;
      .title {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        font-weight: 700;
        color: #fff;
      }
      div {
        margin: 0;
        padding: 0;
        border: 0;
        font: inherit;
        vertical-align: baseline;
        color: #61b0ff;
        .text-org {
          margin: 0;
          padding: 0;
          border: 0;
          font: inherit;
          vertical-align: baseline;
          text-align: center;
          color: hsla(0, 0%, 100%, 0.7);
        }
      }
    }
    .clickBtn {
      border: none;
      outline: none;
      background: #fff;
      border-radius: 100%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1d3662;
      margin-left: 3vw;
      width: 7vw;
      height: 7vw;
    }
  }
  .info {
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    color: #64789b;
    margin: 2vw 0;
    ul {
      display: inline-block;
      font-size: clamp(14px, 3.9062499999vw, 30px);
      li {
        color: #64789b;
        list-style: none;
        padding: 0;
        border: 0;
        display: flex;
        flex-direction: column;
        font: inherit;
        vertical-align: baseline;
        line-height: 6vw;
        width: 100%;
        margin: 0 0 2vw;
        .title {
          font-weight: 700;
        }
        .info ul li span {
          display: -webkit-flex;
          display: flex;
        }
        .info ul li span.title {
          color: #052561;
        }
      }
    }
  }
  .btn-group {
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    margin: 2vw 0;
    display: flex;
    width: 100%;
    .lightBlue {
      margin: 0;
      border: 0;
      font: inherit;
      vertical-align: baseline;
      text-decoration: none;
      display: flex;
      width: 100%;
      border-radius: 3px;
      padding: 2vw 3vw;
      justify-content: center;
      flex: 1 1;
      align-items: center;
      font-size: 3.5vw;
      margin-right: 0.5vw;
      margin-left: 0.5vw;
      background-color: #1e85ec;
      color: #fff;
    }
  }
}

.btn-login {
  .ant-form-item-control {
    max-width: 100%;
  }
  button {
    width: 100%;
  }
}

.title-login {
  background-color: white;

  padding: 10px;
  border-radius: 12px;
  margin-bottom: 15px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  img {
    width: 50px;
    height: auto;
  }
}

// .form-login {
//   background-image: url('../../assets/admin.png');
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center;
//   position: relative;
//   &::before {
//     position: absolute;
//     content: '';
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba($color: #000000, $alpha: 0.6);
//   }
// }

.layout-full {
  width: 100%;
}

.layout-max-help {
  max-width: 912px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  @media screen and (max-width: 1400px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
}

.hide {
  @media screen and (max-width: 900px) {
    display: none;
  }
}
/*common*/
// .ant-popover-content {
//   .ant-popover-arrow {
//     display: none !important;
//   }
// }

/*header main layout*/
.header-main-layout {
  background-color: #030c1b;
  height: 65px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  @media screen and (max-width: 1400px) {
    padding: 0 0px;
  }
}

.layout-helper {
  background: #152849;
  min-height: 100vh;
  height: 100%;
  .content-help {
    margin-top: 65px;
    background-color: white;
  }
  .btn-nav {
    color: white;
    font-size: clamp(25px, 5.85937499985vw, 45px);
    display: block !important;
  }
  .nav-wrapper {
    .ant-drawer-content {
      background: #f2f4f7;
    }
  }

  .container-drawr {
    display: none;
  }
  .menu-body-container {
    padding: 0 24px;
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border-right: none;
    }
    .ant-menu-item-only-child {
      padding: 0 !important;
    }
    .ant-menu-submenu-inline {
      border-bottom: 1px solid #ccc;
    }
    .ant-menu-sub {
      .ant-menu-item-only-child {
        padding: 0 24px !important;
      }
    }
    .ant-menu-root > li {
      border-bottom: 1px solid #ccc;
    }
    .ant-menu-submenu-title {
      padding: 0 !important;
    }
    padding-bottom: 20px;
  }
  .header-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left {
      display: flex;
      align-items: center;
      gap: 10px;
      .logo-img {
        height: 52px;
        @media screen and (max-width: 768px) {
          height: clamp(20px, 4.16666666656vw, 32px);
        }
        img {
          max-height: 100%;
          width: auto;
        }
      }
    }
    &__right {
      .function {
        display: flex;
        gap: 5px;
        align-items: center;
      }
      .function {
        display: flex;
        gap: 20px;
      }
      .dowload-app {
        color: #fff92c;
        @media screen and (max-width: 768px) {
          display: none !important;
        }
      }
      .menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      &__right {
        width: 100%;
        .function {
          justify-content: space-between;
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      &__right {
        width: 100%;
        .function {
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}
@function ratioMobile($min, $max) {
  @return clamp(#{$min}px, #{(100/768) * $min}vw, #{$max}px);
}
